import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Box } from '@mui/material';
import { useState } from 'react';
import { ErrorsToolbar } from './ErrorsToolbar/ErrorsToolbar';
import styles from './styles.module.scss';
import ErrorList from './ErrorsToolbar/ErrorList/ErrorList';
export function Toolbar() {
    const [isAccordianExpanded, setIsAccordianExpanded] = useState(false);
    return (_jsxs("div", { className: styles.toolbarContainer, children: [_jsx(Grid, { className: styles.toolbar, children: _jsx(ErrorsToolbar, { isAccordianExpanded: isAccordianExpanded, setIsAccordianExpanded: setIsAccordianExpanded }) }), _jsx(Box, { children: _jsx(ErrorList, { visible: isAccordianExpanded }) })] }));
}
