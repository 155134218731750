var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';
/* eslint-disable @typescript-eslint/no-explicit-any */
export function MRTTable(_a) {
    var { rows, columns, handleCellClick, loading, enableVirtualization = false, filterComponent: FilterComponent, enableToolbarInternalActions = false, enableBottomToolbar = false, enablePagination = false, enableColumnActions = false, cellClassName, filterProps, table } = _a, restProps = __rest(_a, ["rows", "columns", "handleCellClick", "loading", "enableVirtualization", "filterComponent", "enableToolbarInternalActions", "enableBottomToolbar", "enablePagination", "enableColumnActions", "cellClassName", "filterProps", "table"]);
    const rowsWithErrorInfo = useMemo(() => {
        return rows === null || rows === void 0 ? void 0 : rows.map(row => {
            var _a;
            return (Object.assign(Object.assign({}, row), { hasError: (row === null || row === void 0 ? void 0 : row.hasError) || ((_a = Object.values(row)) === null || _a === void 0 ? void 0 : _a.some(cell => cell === null || cell === void 0 ? void 0 : cell.error)) }));
        });
    }, [rows]);
    return (_jsx(MaterialReactTable, Object.assign({ columns: columns, data: rowsWithErrorInfo, state: { isLoading: loading }, defaultDisplayColumn: { enableResizing: true }, enableTopToolbar: true, enableStickyHeader: true, enableToolbarInternalActions: enableToolbarInternalActions, enableBottomToolbar: enableBottomToolbar, enablePagination: enablePagination, enableColumnActions: enableColumnActions, enableColumnVirtualization: enableVirtualization, enableRowVirtualization: enableVirtualization, columnVirtualizerOptions: { overscan: 2 }, rowVirtualizerOptions: { overscan: 10 }, muiTablePaperProps: {
            sx: {
                overflow: 'unset'
            }
        }, renderTopToolbar: ({ table }) => (_jsx(Box, { sx: {
                display: 'flex',
                position: 'sticky',
                left: 0,
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '',
                padding: FilterComponent ? '16px 24px' : 0
            }, children: FilterComponent && _jsx(FilterComponent, Object.assign({ table: table }, filterProps)) })), muiBottomToolbarProps: {
            sx: {
                position: 'sticky',
                left: 0,
                width: '100vw',
                height: '55px',
                '.MuiTablePagination-root': {
                    fontSize: '20px',
                    gap: 'var(--spacing-24)'
                },
                '.MuiFormLabel-root': {
                    fontSize: '1.2rem'
                },
                '.MuiTypography-root': {
                    fontSize: '1.2rem'
                }
            }
        }, muiTableContainerProps: {
            sx: {
                maxHeight: 'unset',
                maxWidth: 'unset',
                borderColor: 'var(--color-border)',
                overflow: 'unset',
                fontSize: '1.6rem',
                width: 'auto'
            }
        }, muiTableBodyCellProps: ({ cell }) => ({
            onClick: () => handleCellClick && handleCellClick(cell),
            className: cellClassName,
            sx: {
                cursor: 'pointer',
                fontSize: '1.6rem',
                color: 'var(--color-text-primary)',
                fontWeight: 400,
                fontStyle: 'normal',
                maxWidth: '25rem',
                whiteSpace: 'nowrap',
                width: 'fit-content',
                textOverflow: 'ellipsis',
                height: '5.2rem',
                '&:focus, &:focus-within': {
                    outline: 'unset'
                }
            }
        }), muiTableHeadProps: {
            sx: {
                top: 0
            }
        }, muiTableHeadCellProps: {
            sx: {
                backgroundColor: 'var(--color-table-header)',
                color: 'var(--color-text-secondary)',
                fontSize: '1.2rem',
                fontWeight: 600,
                height: '5.6rem',
                paddingTop: '2rem',
                width: 'fit-content',
                '&:focus, &:focus-within': {
                    outline: 'unset'
                },
                '& .MuiButtonBase-root': {
                    opacity: 0
                },
                '&:hover .MuiButtonBase-root': {
                    opacity: 1
                }
            }
        }, muiTableBodyRowProps: ({ row }) => ({
            sx: Object.assign(Object.assign({}, (row.original.hasError && {
                backgroundColor: 'var(--color-error-background)',
                borderLeft: '4px solid var(--color-error-icon)',
                '& td': {
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'transparent',
                        pointerEvents: 'none'
                    }
                },
                '&:hover td::after': {
                    backgroundColor: 'var(--color-error-background-hover) !important'
                }
            })), { '&:last-child td': {
                    borderBottom: 'none'
                } })
        }), muiTableHeadRowProps: {
            sx: {
                '& th': {
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    '&:last-child': {
                        borderRight: 'none'
                    }
                }
            }
        }, muiTableBodyProps: {
            sx: {
                '& td': {
                    borderRight: '1px solid var(--color-border)',
                    position: 'relative',
                    '&:last-child': {
                        borderRight: 'none'
                    }
                },
                '& tr:hover td::after': {
                    backgroundColor: 'var(--color-table-header)'
                }
            }
        } }, restProps)));
}
