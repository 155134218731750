import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Box, Button, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { useMemo, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdEditNote } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/constants';
import { useSummaryStore } from '@entities/summary';
import styles from './SummaryTable.module.scss';
import { useCurrentProject } from '@entities/project';
export function NotRelevantTable({ document, data, documentId }) {
    const navigate = useNavigate();
    const { reset } = useSummaryStore();
    const [show, setShow] = useState(true);
    const { t } = useTranslation();
    const { project } = useCurrentProject();
    const notRelevantPageIds = useMemo(() => getNotRelevantPageIds(document, data), [document, data]);
    if (notRelevantPageIds.length === 0) {
        return null;
    }
    return (_jsxs(Box, { className: styles.notRelevantContainer, children: [_jsx(Button, { className: styles.notRelevantButton, color: 'secondary', variant: 'text', size: 'medium', endIcon: show ? _jsx(MdArrowDropUp, {}) : _jsx(MdArrowDropDown, {}), onClick: () => setShow(!show), children: t('Not relevant pages') }), show ? (_jsx(Box, { className: styles.notRelevantContent, children: _jsx(Table, { className: styles.resultTable, children: _jsx(TableBody, { children: notRelevantPageIds === null || notRelevantPageIds === void 0 ? void 0 : notRelevantPageIds.map((pageId, index) => (_jsx(TableRow, { children: _jsx(TableCell, { className: styles.resultTableCell, size: 'small', children: _jsxs("div", { className: styles.notRelevantCell, children: [_jsxs("div", { children: [_jsx("span", { children: document.fileName }), _jsx("span", { children: `${t('Page')} ${pageId + 1}` })] }), _jsx("div", { children: _jsx(IconButton, { onClick: () => handleEditPage(pageId), children: _jsx(MdEditNote, {}) }) })] }) }) }, index))) }) }) })) : null] }));
    function handleEditPage(pageId) {
        if (documentId) {
            navigate(ROUTES.DOCUMENT_REVIEW.replace(':projectId', project.id)
                .replace(':docId', documentId)
                .replace(':pageId', pageId.toString()));
            reset();
        }
    }
}
function getNotRelevantPageIds(document, extractionData) {
    // Create PageIds On FrontEnd
    const allPageIds = new Array(document.pageCount).fill(0).map((_, idx) => idx);
    const relevantPageIds = extractionData.reduce((acc, item) => {
        // TODO create a separate type for final result file
        if (!item.pageNumber) {
            return acc;
        }
        const pageId = item.pageNumber - 1;
        if (acc.includes(pageId)) {
            return acc;
        }
        acc.push(pageId);
        return acc;
    }, []);
    return allPageIds.filter(v => !relevantPageIds.includes(v));
}
