import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { DialogContainer, DialogActions, DialogContent, DialogTitle } from '@shared/ui/Dialog';
import { useTranslation } from 'react-i18next';
import { useTranslationProject } from '@shared/lib/hooks';
import styles from './CreateProjectDialog.module.scss';
import { ProjectForm } from '@entities/project';
import { useCreateProject } from '@entities/project/lib/hooks/useCreateProject';
import { CircularLoader } from '@shared/ui/Loader';
import { ToastService } from '@shared/lib/services';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/constants';
import { Acknowledgement } from './Acknowledgment';
import { useState } from 'react';
export function CreateProjectDialog({ onClose }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    const onSuccess = (data) => {
        ToastService.success(tproject('Project created'));
        onClose();
        navigate(ROUTES.PROJECT_DOCUMENTS.replace(':projectId', data.id));
    };
    const { create, isCreating } = useCreateProject(onSuccess);
    const handleSubmit = (formValues) => {
        create(formValues);
    };
    const [isAckChecked, setIsAckChecked] = useState(false);
    return (_jsxs(DialogContainer, { className: styles.container, fullWidth: true, open: true, children: [_jsx(DialogTitle, { variant: "h5", children: tproject('New Project') }), _jsxs(DialogContent, { children: [_jsx(ProjectForm, { name: '', location: '', onSubmit: handleSubmit }), _jsx(Acknowledgement, { isAckChecked: isAckChecked, handleToggleAck: val => setIsAckChecked(val) })] }), _jsxs(DialogActions, { className: styles.actions, children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('Cancel') }), _jsx(Button, { type: "submit", form: ProjectForm.FORM_ID, variant: "contained", disabled: isCreating || !isAckChecked, endIcon: isCreating ? _jsx(CircularLoader, { size: "1em", color: "inherit" }) : undefined, children: tproject('Create Project') })] })] }));
}
