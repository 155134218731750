import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Tabs, Tab, Typography, Divider } from '@mui/material';
import styles from './ProjectTabs.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentProject } from '@entities/project';
import { ROUTES } from '@app/constants';
import { useTranslationProject } from '@shared/lib/hooks';
import { ProjectTabsEnum } from '../lib/types/ProjectTabTypes';
import classNames from 'classnames';
export function ProjectTabs({ setActiveTab }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { project } = useCurrentProject();
    const { tproject } = useTranslationProject();
    const currentTab = pathname.substring(pathname.lastIndexOf('/') + 1);
    useEffect(() => {
        setActiveTab(currentTab);
    }, [currentTab, setActiveTab]);
    const handleTabChange = (_event, newValue) => {
        const routeMap = {
            [ProjectTabsEnum.SUMMARY]: ROUTES.PROJECT_SUMMARY,
            [ProjectTabsEnum.DOCUMENTS]: ROUTES.PROJECT_DOCUMENTS,
            [ProjectTabsEnum.USERS]: ROUTES.PROJECT_USERS,
            [ProjectTabsEnum.ARCHIVED]: ROUTES.PROJECT_ARCHIVED
        };
        const routeTemplate = routeMap[newValue];
        const route = routeTemplate.replace(':projectId', project.id);
        navigate(route);
    };
    return (_jsx("div", { className: styles.tabsContainer, children: _jsxs(Tabs, { value: currentTab, onChange: handleTabChange, className: styles.tabs, TabIndicatorProps: { hidden: true }, children: [_jsx(Tab, { value: ProjectTabsEnum.SUMMARY, label: _jsxs(Typography, { variant: "button", className: styles.tabTitle, children: [tproject('Summary table'), _jsx(Divider, { orientation: "vertical", className: styles.divider })] }), className: classNames(styles.projectTab, currentTab === ProjectTabsEnum.SUMMARY ? styles.activeTab : '') }), _jsx(Tab, { value: ProjectTabsEnum.ARCHIVED, label: _jsxs(Typography, { variant: "button", className: styles.tabTitle, children: [tproject('Archived'), _jsx(Divider, { orientation: "vertical", className: styles.divider })] }), className: classNames(styles.projectTab, currentTab === ProjectTabsEnum.ARCHIVED ? styles.activeTab : '') }), _jsx(Tab, { value: ProjectTabsEnum.DOCUMENTS, style: { position: 'relative' }, label: _jsxs(Typography, { variant: "button", className: styles.tabTitle, children: [tproject('Files'), _jsx(Divider, { orientation: "vertical", className: styles.divider })] }), className: classNames(styles.projectTab, currentTab === ProjectTabsEnum.DOCUMENTS ? styles.activeTab : '') }), _jsx(Tab, { value: ProjectTabsEnum.USERS, label: _jsxs(Typography, { variant: "button", className: styles.tabTitle, children: [' ', tproject('Users')] }), className: classNames(styles.projectTab, currentTab === ProjectTabsEnum.USERS ? styles.activeTab : '') })] }) }));
}
