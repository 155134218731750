import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InviteUserDialog } from '@entities/user/ui';
import { useTranslationUser } from '@shared/lib/hooks';
import { Button } from '@shared/ui/Button';
import { useBoolean } from '@conxai/react-kit';
export function InviteUser() {
    const { tuser } = useTranslationUser();
    const [isInviteUserDialogOpen, , showInviteUserDialog, hideInviteUserDialog] = useBoolean(false);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", onClick: showInviteUserDialog, children: tuser('Invite user') }), isInviteUserDialogOpen && _jsx(InviteUserDialog, { onClose: hideInviteUserDialog })] }));
}
