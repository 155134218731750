import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, IconButton, Checkbox, ListItemText } from '@mui/material';
import { Select } from '@shared/ui/Select';
import { TextField } from '@shared/ui/TextField';
import { MenuItem } from '@shared/ui/Menu';
import { Replay } from '@shared/ui/icons';
import styles from './ResultTableFilters.module.scss';
import { ResultFieldFormat } from '@shared/types/ResultField';
import { useSummaryStore, getSelectFilterOptions, resultFields } from '@entities/summary';
import DataGroupToggle from './DataGroupToggle';
import { ICCheckbox } from './ICCheckbox';
import { DateRange } from './DateRange';
export function ResultTableFilters({ table, groupingStatus, handleChangeGroupingStatus, isChecked, handleToggle, handleRowsDeselection }) {
    const [filterValues, setFilterValues] = useState({});
    const { t } = useTranslation();
    const selectFields = useMemo(() => getSelectFields(), []);
    const fieldOptions = useSummaryStore(getSelectFilterOptions(selectFields));
    const filterFields = useMemo(() => makeFilterFields(t, fieldOptions), [t, fieldOptions]);
    const handleFilterChange = (id, value) => {
        setFilterValues(prev => (Object.assign(Object.assign({}, prev), { [id]: value })));
        table.setColumnFilters(prev => {
            const updatedFilters = prev.filter(f => f.id !== id);
            updatedFilters.push({ id, value });
            return updatedFilters;
        });
        handleRowsDeselection && handleRowsDeselection();
    };
    const handleReset = () => {
        setFilterValues({});
        handleToggle(false);
        table.resetColumnFilters();
    };
    return (_jsx(_Fragment, { children: (filterFields === null || filterFields === void 0 ? void 0 : filterFields.length) > 0 && (_jsxs(Box, { className: styles.filterContainer, children: [_jsx(DataGroupToggle, { groupingStatus: groupingStatus, handleChangeGroupingStatus: handleChangeGroupingStatus }, 'data-group'), filterFields.map(({ id, placeHolder, filterOptions = [], format }) => (_jsx(_Fragment, { children: format === ResultFieldFormat.select ? (_jsx("div", { className: styles.field, children: _jsxs(FormControl, { className: styles.textContainer, children: [_jsx(InputLabel, { id: `${id}-label`, className: styles.text, children: placeHolder }), _jsx(Select, { labelId: `${id}-label`, multiple: true, value: filterValues[id] || [], onChange: (event) => {
                                        const { target: { value } } = event;
                                        handleFilterChange(id, typeof value === 'string' ? value.split(',') : value);
                                    }, className: styles.text, label: placeHolder, renderValue: (selected) => selected.join(', '), children: filterOptions.map(option => (_jsxs(MenuItem, { value: option, children: [_jsx(Checkbox, { checked: (filterValues[id] || []).includes(option) }), _jsx(ListItemText, { primary: option })] }, option))) }, id)] }) }, id)) : format === ResultFieldFormat.dateTime ? (_jsx(DateRange, { value: filterValues[id], onChange: v => handleFilterChange(id, v), label: placeHolder })) : (_jsx("div", { className: styles.field, children: _jsx(TextField, { className: styles.textContainer, label: placeHolder, variant: "outlined", value: filterValues[id] || '', onChange: e => handleFilterChange(id, e.target.value), InputLabelProps: { shrink: true } }, id) }, id)) }))), _jsx(ICCheckbox, { handleToggle: handleToggle, isChecked: isChecked }), _jsx(IconButton, { className: styles.resetButton, onClick: handleReset, children: _jsx(Replay, { className: styles.resetIcon }) })] })) }));
}
function makeFilterFields(t, fieldOptions) {
    return resultFields
        .filter(property => property.filter)
        .map(property => (Object.assign({ id: property.property, placeHolder: t(property.label), format: property.format }, (property.format === ResultFieldFormat.select && {
        filterOptions: fieldOptions[property.property]
    }))));
}
function getSelectFields() {
    return resultFields
        .filter(property => property.filter && property.format === ResultFieldFormat.select)
        .map(item => item.property);
}
