import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@shared/ui/Button';
import styles from './WatchDemo.module.scss';
import { SmartDisplay } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, Dialog } from '@mui/material';
import React, { useRef } from 'react';
import YouTube from 'react-youtube';
export function WatchDemo() {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const playerRef = useRef(null);
    const onClose = () => {
        setOpen(false);
        stopVideo();
    };
    const onReady = (event) => {
        playerRef.current = event.target;
    };
    const stopVideo = () => {
        if (playerRef.current) {
            playerRef.current.stopVideo();
        }
    };
    const opts = {
        playerVars: {
            autoplay: 1,
            controls: 1,
            modestbranding: 1,
            rel: 0
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: `medium`, variant: "text", className: styles.watchDemo, onClick: () => setOpen(true), startIcon: _jsx(SmartDisplay, { color: `primary` }), children: t('Watch demo') }), _jsx(Dialog, { className: styles.dialog, open: open, onClose: onClose, children: _jsx(Box, { className: styles.content, children: _jsx("div", { className: styles.videoContainer, children: _jsx(YouTube, { videoId: "xbTPk0qDMjI", opts: opts, onReady: onReady }) }) }) })] }));
}
