import { memoize } from 'proxy-memoize';
import { DocumentGroupedResultRowType } from '../../types/DocumentResultRowType';
import { PageTypes } from '@shared/types';
import { isFieldTypeTagOrDelivery } from '@shared/utils/isFieldTypeTagOrDelivery';
const getGroupedResultDataByICMemo = memoize((data) => {
    const groupedResultDataByIC = {
        [DocumentGroupedResultRowType.unassigned]: [],
        [DocumentGroupedResultRowType.grouped]: {}
    };
    if (!data) {
        return groupedResultDataByIC;
    }
    const certificates = data.filter(rowItem => rowItem.type === PageTypes.certificate);
    const tagsAndDeliveryNotes = data.filter(rowItem => isFieldTypeTagOrDelivery(rowItem.type));
    certificates.forEach(certificate => {
        groupedResultDataByIC[DocumentGroupedResultRowType.grouped][certificate.index] = [certificate];
    });
    tagsAndDeliveryNotes.forEach(rowItem => {
        if ((rowItem === null || rowItem === void 0 ? void 0 : rowItem.icIndex) !== null) {
            groupedResultDataByIC[DocumentGroupedResultRowType.grouped][rowItem.icIndex].push(rowItem);
        }
        else {
            groupedResultDataByIC[DocumentGroupedResultRowType.unassigned].push(Object.assign(Object.assign({}, rowItem), { isLastItem: true }));
        }
    });
    return groupedResultDataByIC;
});
export const getGroupedResultDataByIC = ({ data }) => {
    return getGroupedResultDataByICMemo(data);
};
