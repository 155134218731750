var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from '../Tooltip/Tooltip';
import { trimWithEllipsis } from '@conxai/react-kit';
export function TrimTextToolTip(_a) {
    var { maxMessageLength = 50 } = _a, restProps = __rest(_a, ["maxMessageLength"]);
    const { title } = restProps;
    const trimmedText = trimWithEllipsis(typeof title === 'string' ? title : '', maxMessageLength);
    return (_jsx(Tooltip, Object.assign({}, restProps, { children: _jsx("span", { children: trimmedText }) })));
}
