import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from '../../Header/Header';
import styles from './Layout.module.scss';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import { Navigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentProject } from '@entities/project';
import { LoadingPagePlaceholder } from '@shared/ui/LoadingPagePlaceholder';
import { Footer } from '../../Footer/Footer';
import { ProtectedLayoutWrapper } from '@entities/auth';
import { ProjectHeader } from '../../ProjectHeader';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { trimWithEllipsis } from '@conxai/react-kit';
import { ROUTES } from '@app/constants';
export function Layout() {
    const { project, isFetching, isError } = useCurrentProject();
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (isFetching) {
        return _jsx(LoadingPagePlaceholder, {});
    }
    if (isError) {
        return _jsx(Navigate, { to: ROUTES.ERROR, replace: true });
    }
    return (_jsx(ProtectedLayoutWrapper, { children: _jsxs(Box, { className: styles.layout, children: [_jsx(Header, { children: _jsxs(Breadcrumbs, { "aria-label": "breadcrumb", children: [_jsx(Link, { className: styles.breadcrumbLink, underline: "hover", color: "inherit", onClick: () => navigate('/'), children: t('Projects') }), _jsx(Typography, { className: "breadcrumb", children: trimWithEllipsis(project.name, 50) })] }) }), _jsxs(Box, { className: styles.content, children: [_jsx(ProjectHeader, {}), _jsx(Outlet, {})] }), _jsx(Footer, {})] }) }));
}
