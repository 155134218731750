var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Select as SelectMui } from '@mui/material';
import styles from './Select.module.scss';
import classNames from 'classnames';
import { KeyboardArrowDownIcon } from '../icons';
export function Select(_a) {
    var { className, variant, classes } = _a, restProps = __rest(_a, ["className", "variant", "classes"]);
    const _b = classes || {}, { icon: iconClassName } = _b, restClasses = __rest(_b, ["icon"]);
    return (_jsx(SelectMui, Object.assign({ className: classNames(className, styles.root, {
            [styles.variant__rounded]: variant === 'rounded'
        }), IconComponent: KeyboardArrowDownIcon, classes: Object.assign({ icon: classNames(styles.icon, iconClassName) }, restClasses), variant: variant === 'rounded' ? 'outlined' : variant }, restProps)));
}
