import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSummaryStore } from '@entities/summary';
import { useTranslation } from 'react-i18next';
export function useArchivedSummary() {
    const { projectId } = useParams();
    const { t } = useTranslation();
    const { data, fetchArchived, reset } = useSummaryStore();
    useEffect(() => {
        if (projectId) {
            fetchArchived(projectId);
        }
        return () => {
            reset();
        };
    }, [fetchArchived, projectId, t]);
    return {
        data
    };
}
