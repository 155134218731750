import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ArchivedTableSkeleton } from './ArchivedTableSkeleton';
import { ProjectSummaryTable } from '@features/summaryTable';
import styles from './ArchivedTable.module.scss';
import { useTranslationProject } from '@shared/lib/hooks';
import { useArchivedSummary } from '@entities/project/lib/hooks/useArchivedSummary';
export function ArchivedTable() {
    const { tproject } = useTranslationProject();
    const { data } = useArchivedSummary();
    if (!data) {
        return _jsx(ArchivedTableSkeleton, {});
    }
    return (_jsxs(_Fragment, { children: [data.length === 0 && (_jsx("div", { className: styles.containerEmpty, children: tproject('No archived documents found') })), _jsx("div", { className: styles.container, children: data.length > 0 && _jsx(ProjectSummaryTable, { data: data, isArchived: true }) })] }));
}
