import { ResultFieldFormat } from '@shared/types/ResultField';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
export const resultFields = [
    {
        label: 'Page Number',
        property: ResultFieldProperties.pageNumber,
        columnWidth: 100
    },
    {
        label: 'File name',
        property: ResultFieldProperties.filename,
        format: ResultFieldFormat.input,
        columnWidth: 190
    },
    {
        label: 'Document type',
        property: ResultFieldProperties.type,
        columnWidth: 100
    },
    {
        label: 'Company name',
        property: ResultFieldProperties.companyName,
        filter: true,
        format: ResultFieldFormat.select,
        columnWidth: 190
    },
    {
        label: 'Shipping date',
        property: ResultFieldProperties.shippingDate,
        filter: true,
        format: ResultFieldFormat.dateTime,
        columnWidth: 100
    },
    {
        label: 'Certificate number',
        property: ResultFieldProperties.certificateNumber,
        columnWidth: 150
    },
    {
        label: 'Document number',
        property: ResultFieldProperties.documentNumber,
        columnWidth: 100
    },
    {
        label: 'Material & Standard Name',
        property: ResultFieldProperties.materialName,
        filter: true,
        format: ResultFieldFormat.select,
        columnWidth: 150
    },
    {
        label: 'Total amount',
        property: ResultFieldProperties.totalAmount,
        columnWidth: 100
    },
    {
        label: 'Diameter',
        property: ResultFieldProperties.diameter,
        columnWidth: 100
    },
    {
        label: 'Length',
        property: ResultFieldProperties.length,
        columnWidth: 100
    },
    {
        label: 'ID',
        property: ResultFieldProperties.id,
        columnWidth: 100
    },
    {
        label: 'Quantities',
        property: ResultFieldProperties.quantities,
        columnWidth: 100
    }
];
