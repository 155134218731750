import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox, FormControlLabel } from '@mui/material';
import styles from './CreateProjectDialog.module.scss';
import { useTranslationProject } from '@shared/lib/hooks';
export function Acknowledgement({ handleToggleAck, isAckChecked }) {
    const { tproject } = useTranslationProject();
    return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: isAckChecked, onChange: e => {
                handleToggleAck(e.target.checked);
            }, className: styles.checkbox, size: "medium" }), label: tproject('By creating a new project I acknowledge that billing for the project will commence.'), classes: {
            root: styles.formControlLabel,
            label: styles.label
        } }));
}
