import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { GroupingRowType } from '@entities/summary';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import filterStyle from './ResultTableFilters.module.scss';
const DataGroupToggle = ({ groupingStatus, handleChangeGroupingStatus }) => {
    const { t } = useTranslation();
    const handleChange = (event) => {
        handleChangeGroupingStatus(event.target.value);
    };
    return (_jsx("div", { className: filterStyle.field, children: _jsxs(FormControl, { className: filterStyle.textContainer, children: [_jsx(InputLabel, { id: `grouplabel`, className: filterStyle.text, children: t('Grouping Status') }), _jsxs(Select, { labelId: 'grouplabel', value: groupingStatus, onChange: handleChange, label: t('Grouping Status'), className: filterStyle.text, children: [_jsx(MenuItem, { className: styles.item, value: GroupingRowType.ungrouped, children: t(GroupingRowType.ungrouped) }), _jsx(MenuItem, { className: styles.item, value: GroupingRowType.grouped, children: t(GroupingRowType.grouped) })] })] }) }, 'grouplabel'));
};
export default DataGroupToggle;
