var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Logo from '@shared/assets/icons/logo.svg';
import { ROUTES } from '@app/constants';
import styles from './Header.module.scss';
import { useAuth } from '@entities/auth';
import { useAnchorElement } from '@conxai/react-kit';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '@entities/languageSwitcher';
import { WatchDemo } from '@features/watchDemo';
export function Header({ children }) {
    const { logout, user } = useAuth();
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { anchor, setAnchor, clearAnchor } = useAnchorElement();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleLogout = () => __awaiter(this, void 0, void 0, function* () {
        setIsLoggingOut(true);
        yield logout();
        setIsLoggingOut(false);
        clearAnchor();
    });
    const handleProceed = () => {
        navigate(ROUTES.PROJECTS);
    };
    const open = Boolean(anchor);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.left, children: [_jsx("div", { className: styles.brand, onClick: handleProceed, children: _jsx(Logo, {}) }), _jsx("div", { className: styles.breadcrumbsContainer, children: children ? children : null })] }), _jsxs("div", { className: styles.right, children: [_jsx(WatchDemo, {}), _jsx(LanguageSwitcher, {}), _jsx("div", { className: styles.avatar, onClick: setAnchor, children: user && user.email && user.email[0].toUpperCase() })] }), _jsxs(Menu, { className: styles.profile, open: open, anchorEl: anchor, onClose: clearAnchor, children: [_jsx(MenuItem, { children: user ? user.email : '' }), _jsxs(MenuItem, { onClick: handleLogout, disabled: isLoggingOut, children: [_jsx(MdOutlineLogout, {}), t('Sign out')] })] })] }) }));
}
export default Header;
