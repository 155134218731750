import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { DownloadButton } from './Download/DownloadButton';
import { Typography } from '@shared/ui/Typography';
import styles from './SummaryTable.module.scss';
import { useTranslation } from 'react-i18next';
export function Header({ documentName }) {
    const { t } = useTranslation();
    return (_jsxs(Box, { className: styles.headerToolbar, children: [_jsxs("div", { children: [_jsx(Typography, { variant: 'h1', children: t('Document summary') }), _jsx("div", { children: documentName && documentName })] }), _jsx(DownloadButton, {})] }));
}
