import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Header from '../../Header/Header';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import styles from './Layout.module.scss';
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTranslationProject } from '@shared/lib/hooks';
import { useCurrentProject } from '@entities/project';
import { LoadingPagePlaceholder } from '@shared/ui/LoadingPagePlaceholder';
import { Footer } from '../../Footer/Footer';
import { ProtectedLayoutWrapper } from '@entities/auth';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { ROUTES } from '@app/constants';
import { trimWithEllipsis } from '@conxai/react-kit';
import { isErrorFetching } from '@entities/project/document/';
export function Layout() {
    const { project, isFetching, isError: isErrorFetchingProject } = useCurrentProject();
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isErrorFetchingDocument = isErrorFetching();
    const [documentBreadcrumb, setDocumentBreadcrumb] = useState('');
    const getBreadcrumb = (pathname) => {
        if (pathname.includes('/summary'))
            return t('Document summary');
        if (pathname.includes('/pages'))
            return tproject('Document Review');
        return '';
    };
    useEffect(() => {
        setDocumentBreadcrumb(getBreadcrumb(pathname));
    }, [pathname, tproject]);
    if (isFetching) {
        return _jsx(LoadingPagePlaceholder, {});
    }
    if (isErrorFetchingProject || isErrorFetchingDocument) {
        return _jsx(Navigate, { to: ROUTES.ERROR, replace: true });
    }
    return (_jsx(ProtectedLayoutWrapper, { children: _jsxs(Box, { className: styles.layout, children: [_jsx(Header, { children: documentBreadcrumb && (_jsxs(Breadcrumbs, { "aria-label": "breadcrumb", children: [_jsx(Link, { className: styles.breadcrumbLink, underline: "hover", color: "inherit", onClick: () => navigate('/'), children: t('Projects') }), _jsx(Link, { className: styles.breadcrumbLink, underline: "hover", color: "inherit", onClick: () => navigate(ROUTES.PROJECT_DOCUMENTS.replace(':projectId', project.id)), children: trimWithEllipsis(project.name, 50) }), _jsx(Typography, { className: "breadcrumb", children: documentBreadcrumb })] })) }), _jsx(Box, { className: styles.content, children: _jsx(Outlet, {}) }), _jsx(Footer, {})] }) }));
}
