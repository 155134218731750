export var FormatOptionsEnum;
(function (FormatOptionsEnum) {
    FormatOptionsEnum["tokio"] = "Tokyo";
    FormatOptionsEnum["other"] = "Other Branches";
})(FormatOptionsEnum || (FormatOptionsEnum = {}));
export var FormatOptionsEnumKey;
(function (FormatOptionsEnumKey) {
    FormatOptionsEnumKey["tokio"] = "tokio";
    FormatOptionsEnumKey["other"] = "other";
})(FormatOptionsEnumKey || (FormatOptionsEnumKey = {}));
