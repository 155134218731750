import { useGetProjects } from '@entities/project';
import { useMemo } from 'react';
export function useFindProject(id) {
    const { projects, isFetching, isError } = useGetProjects();
    const project = useMemo(() => projects.find(project => project.id === id), [id, projects]);
    return {
        project,
        isFetching,
        isError: isError || !project
    };
}
