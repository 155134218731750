import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@shared/ui/Button';
import { useState } from 'react';
import { DownloadModal } from './DownloadModal/DownloadModal';
import { useTranslationProject } from '@shared/lib/hooks';
import { useSummaryStore } from '@entities/summary';
import styles from './styles.module.scss';
export function DownloadButton() {
    const [visibleDialog, setVisibleDialog] = useState(false);
    const { tproject } = useTranslationProject();
    const { data } = useSummaryStore();
    const handleCloseDialog = () => {
        setVisibleDialog(false);
    };
    const handleOpenDialog = () => {
        setVisibleDialog(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", disabled: (data === null || data === void 0 ? void 0 : data.length) === 0, onClick: handleOpenDialog, className: styles.downloadButton, children: tproject('Download') }), _jsx(DownloadModal, { open: visibleDialog, onClose: handleCloseDialog })] }));
}
