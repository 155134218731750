import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { ErrorIcon, WarningIcon, ExpandLess, ExpandMore } from '@shared/ui/icons';
import { getResultErrorsByType, useSummaryStore } from '@entities/summary';
import styles from './ErrorsToolbar.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
export function ErrorsToolbar({ isAccordianExpanded, setIsAccordianExpanded }) {
    const { t } = useTranslation();
    const groupedErrors = useSummaryStore(getResultErrorsByType);
    const hasErrors = groupedErrors.error.length > 0;
    const hasWarnings = groupedErrors.warning.length > 0;
    if (!hasErrors && !hasWarnings) {
        return null;
    }
    return (_jsx(_Fragment, { children: _jsx(Box, { className: styles.container, children: _jsx(Box, { className: styles.header, onClick: () => setIsAccordianExpanded(prev => !prev), children: _jsxs(Box, { className: classNames(styles.toolbar, {
                        [styles.expanded]: isAccordianExpanded,
                        [styles.collapsed]: !isAccordianExpanded
                    }), children: [hasErrors ? (_jsxs(Box, { children: [_jsx(ErrorIcon, {}), " ", `${groupedErrors.error.length} ${t('critical issues')}`] })) : null, hasErrors && hasWarnings ? _jsx(Box, { className: styles.divider }) : null, hasWarnings ? (_jsxs(Box, { children: [_jsx(WarningIcon, {}), " ", `${groupedErrors.warning.length} ${t('warnings')}`] })) : null, isAccordianExpanded ? _jsx(ExpandLess, { height: 24 }) : _jsx(ExpandMore, { height: 24 })] }) }) }) }));
}
