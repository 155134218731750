import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Datepicker, TextField } from '@shared/ui';
import styles from './ResultTableFilters.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { getFormattedDateValue } from '../../utils/getFormattedDateValue';
const commonProps = {
    className: classNames(styles.textContainer, styles.text),
    variant: 'outlined',
    InputLabelProps: { className: styles.text, shrink: true },
    InputProps: { readOnly: true }
};
export function DateRange({ value, onChange, label }) {
    const { t } = useTranslation();
    const valueSanitized = Array.isArray(value) ? value : ['', ''];
    const [anchorEl, setAnchorEl] = useState({ element: null, id: 0, visible: false });
    const onClose = () => {
        setAnchorEl({ id: 0, element: null, visible: false });
    };
    const handleFieldChange = (value) => {
        if (anchorEl.id == 0) {
            return onChange([value, valueSanitized[1]]);
        }
        return onChange([valueSanitized[0], value]);
    };
    const getDatePickerValue = () => {
        return valueSanitized[anchorEl.id];
    };
    const startDateRf = useRef(null);
    const endDateRef = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.field, ref: startDateRf, children: _jsx(TextField, Object.assign({ label: `${label} (${t('Start')})`, value: getFormattedDateValue(valueSanitized[0]), onClick: () => setAnchorEl({ element: startDateRf.current, id: 0, visible: true }) }, commonProps)) }), _jsx("div", { className: styles.field, ref: endDateRef, children: _jsx(TextField, Object.assign({ label: `${label} (${t('End')})`, value: getFormattedDateValue(valueSanitized[1]), onClick: () => setAnchorEl({ element: endDateRef.current, id: 1, visible: true }) }, commonProps)) }), _jsx(Datepicker, { show: anchorEl.visible, anchorEl: anchorEl.element, data: getDatePickerValue(), onClose: onClose, onConfirm: e => handleFieldChange(e.target.value), isResetEnabled: true })] }));
}
