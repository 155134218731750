import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDocumentSummary } from '../lib/hooks/useDocumentSummary';
import { DocumentResultsPreparing } from './DocumentResultsPreparing';
import { Header } from './Header';
import { NotRelevantTable } from './NotRelevantTable';
import { DocumentSummaryTable } from '@features/summaryTable/ui/DocumentSummaryTable';
export function DocumentResultsPage() {
    const { documentId, document, data } = useDocumentSummary();
    if (!documentId || !document || !data) {
        return _jsx(DocumentResultsPreparing, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { documentName: data.length > 0 ? data[0].documentName : undefined }), _jsx(DocumentSummaryTable, { data: data }), _jsx(NotRelevantTable, { document: document, data: data, documentId: documentId })] }));
}
