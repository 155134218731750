import { isFieldTypeTagOrDelivery } from '@shared/utils/isFieldTypeTagOrDelivery';
import { documentTypes } from '@entities/project';
import { resultValueNotAppliable, unmodifiedTagOrDeliveryFields } from '../lib/constants/unmodifiedFields';
export function getFieldValue(row, field, index, t) {
    if (isFieldTypeTagOrDelivery(row.type)) {
        if (unmodifiedTagOrDeliveryFields.includes(field.property)) {
            return resultValueNotAppliable;
        }
    }
    switch (field.property) {
        case 'index': {
            return index + 1;
        }
        case 'filename': {
            return row.documentName || null;
        }
        case 'type': {
            return t(documentTypes[row[field.property]]);
        }
        case 'totalAmount':
        case 'shippingDate':
            return row[field.property] !== null ? row[field.property] : '';
        case 'pageNumber':
        case 'companyName':
        case 'certificateNumber':
        case 'documentNumber':
        case 'materialName':
        case 'diameter':
        case 'id':
        case 'quantities': {
            return row[field.property];
        }
        case 'length': {
            return row[field.property] !== null ? row[field.property] + ' m' : '';
        }
        default: {
            return null;
        }
    }
}
