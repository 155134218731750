var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Expose, Transform } from 'class-transformer';
import { getFormattedTotalAmount } from '@shared/utils';
import { format } from 'date-fns';
import { shippingDateFormat } from '../lib/constants/shippingDateFormat';
export class SummaryModel {
}
__decorate([
    Expose({ name: 'document_id' })
], SummaryModel.prototype, "documentId", void 0);
__decorate([
    Expose({ name: 'index' })
], SummaryModel.prototype, "index", void 0);
__decorate([
    Expose({ name: 'certificate_number' })
], SummaryModel.prototype, "certificateNumber", void 0);
__decorate([
    Expose({ name: 'document_name' })
], SummaryModel.prototype, "documentName", void 0);
__decorate([
    Expose({ name: 'company_name' })
], SummaryModel.prototype, "companyName", void 0);
__decorate([
    Expose({ name: 'diameter' })
], SummaryModel.prototype, "diameter", void 0);
__decorate([
    Expose({ name: 'document_number' })
], SummaryModel.prototype, "documentNumber", void 0);
__decorate([
    Expose({ name: 'id' })
], SummaryModel.prototype, "id", void 0);
__decorate([
    Expose({ name: 'length' })
], SummaryModel.prototype, "length", void 0);
__decorate([
    Expose({ name: 'material_name' })
], SummaryModel.prototype, "materialName", void 0);
__decorate([
    Expose({ name: 'page_number' })
], SummaryModel.prototype, "pageNumber", void 0);
__decorate([
    Expose({ name: 'quantities' })
], SummaryModel.prototype, "quantities", void 0);
__decorate([
    Expose({ name: 'shipping_date' }),
    Transform(({ value }) => (value === null ? null : format(new Date(value), shippingDateFormat)))
], SummaryModel.prototype, "shippingDate", void 0);
__decorate([
    Expose({ name: 'total_amount' }),
    Transform(({ value }) => getFormattedTotalAmount(value))
], SummaryModel.prototype, "totalAmount", void 0);
__decorate([
    Expose({ name: 'type' })
], SummaryModel.prototype, "type", void 0);
__decorate([
    Expose({ name: 'calculated_quantities_tags' })
], SummaryModel.prototype, "calculatedQuantitiesTags", void 0);
__decorate([
    Expose({ name: 'calculated_quantities_delivery' })
], SummaryModel.prototype, "calculatedQuantitiesDelivery", void 0);
__decorate([
    Expose({ name: 'ic_index' })
], SummaryModel.prototype, "icIndex", void 0);
__decorate([
    Expose({ name: 'row_index' })
], SummaryModel.prototype, "rowIndex", void 0);
