import { memoize } from 'proxy-memoize';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
const getSelectFilterOptionsMemo = memoize(({ data, filterFields }) => filterFields.reduce((grouped, fieldKey) => {
    const fieldValues = grouped[fieldKey] || [];
    data.forEach(rowItem => {
        const value = fieldKey === ResultFieldProperties.filename ? '' : rowItem[fieldKey];
        if (value && !fieldValues.includes(value)) {
            fieldValues.push(value);
        }
    });
    grouped[fieldKey] = fieldValues;
    return grouped;
}, {}));
export const getSelectFilterOptions = (filterFields) => ({ data }) => {
    return getSelectFilterOptionsMemo({ data, filterFields });
};
