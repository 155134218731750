import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DialogContainer, DialogActions, DialogContent, DialogTitle } from '@shared/ui/Dialog';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import { ToastService } from '@shared/lib/services';
import { useUpdateSummary } from '@entities/summary';
import { FormatOptionsEnum, FormatOptionsEnumKey } from '../../lib/constants/FormatOptionsEnum';
import { useState } from 'react';
import { useCurrentProject } from '@entities/project';
import { CircularLoader } from '@shared/ui/Loader';
import { Typography } from '@shared/ui/Typography';
export function ExportICDialog({ onClose }) {
    const { t } = useTranslation();
    const [formatOption, setFormatOption] = useState(FormatOptionsEnumKey.tokio);
    const handleRadioChange = (_, value) => {
        setFormatOption(value);
    };
    const { project: { id, name } } = useCurrentProject();
    const onSuccess = (isArchived) => {
        ToastService.success(t(`Files were successfully exported ${isArchived ? 'and archived' : ''}`));
        onClose();
    };
    const { update, isUpdating } = useUpdateSummary(onSuccess, id, name);
    return (_jsxs(DialogContainer, { classes: { paper: styles.container }, open: true, children: [_jsx(DialogTitle, { variant: "h6", children: t('Export selected ICs') }), _jsx(DialogContent, { children: _jsxs(FormControl, { children: [_jsx("div", { id: "format-row-label", className: styles.formLabel, children: t('Export Format') }), _jsxs(RadioGroup, { row: true, "aria-labelledby": "format-row-label", name: "format-row", value: formatOption, onChange: handleRadioChange, className: styles.radioGroup, children: [_jsx(FormControlLabel, { value: FormatOptionsEnumKey.tokio, control: _jsx(Radio, {}), className: styles.radioLabel, label: t(FormatOptionsEnum.tokio) }), _jsx(FormControlLabel, { value: FormatOptionsEnumKey.other, control: _jsx(Radio, {}), className: styles.radioLabel, label: t(FormatOptionsEnum.other) })] })] }) }), _jsxs(DialogActions, { className: styles.actions, disableSpacing: true, children: [_jsx(Button, { variant: "text", onClick: onClose, disableElevation: true, className: styles.cancelButton, disabled: isUpdating, children: t('Cancel') }), _jsx("div", { className: styles.submitActions, children: isUpdating ? (_jsxs(Typography, { variant: "h4", classes: styles.processing, children: [t('Processing'), " ", _jsx(CircularLoader, { size: "0.8em", color: "inherit" }), ' '] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => update({ branch: formatOption, isArchived: false }), variant: "outlined", className: styles.button, children: t('Export') }), _jsx(Button, { onClick: () => update({ branch: formatOption, isArchived: true }), variant: "contained", className: styles.button, children: t('Export and Archive') })] })) })] })] }));
}
