import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Tooltip } from '@shared/ui';
import { resultValueNotAppliable } from '../../lib/constants/unmodifiedFields';
import { useSummaryStore } from '@entities/summary';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
import { CellError } from './CellError';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { getCellErrors } from '../../utils/getCellErrors';
export function TableCell({ value, index, property }) {
    const { groupedErrors } = useSummaryStore();
    const { errors, hasError } = getCellErrors(groupedErrors, property, index);
    if (property === ResultFieldProperties.filename) {
        return (_jsx("div", { className: styles.resultTableCell, children: _jsx(Tooltip, { title: value, children: _jsx(Box, { className: styles.compact, children: value }) }) }));
    }
    return (_jsxs("div", { className: classNames(styles.resultTableCell, {
            [styles.placeholderData]: value === resultValueNotAppliable,
            [styles.missingData]: hasError
        }), children: [_jsx(Tooltip, { title: value, children: _jsx(Box, { className: styles.compact, children: value }) }), hasError ? _jsx(CellError, { errors: errors }) : null] }));
}
