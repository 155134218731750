import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ROUTES } from '@app/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/ui/Button';
import styles from './styles.module.scss';
export function ProjectsFallback() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleButtonClick = () => navigate(ROUTES.PROJECTS, { replace: true });
    return (_jsxs("div", { className: styles.container, children: [_jsxs("p", { className: styles.text, children: [t('Sorry, something went wrong'), "."] }), _jsx(Button, { variant: "outlined", onClick: handleButtonClick, children: t('Back to projects') })] }));
}
