var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReferrer, useTranslationProject } from '@shared/lib/hooks';
import { ROUTES } from '@app/constants';
import { ToastService } from '@shared/lib/services';
import { DocumentStatuses } from '@shared/types/DocumentStatuses';
import { DocumentService } from '../services/Document.service';
import { useDocumentStore } from '@entities/project/document';
import { preparePageDataOnSave } from '../utils/preparePageDataOnSave';
import { PageStatuses } from '@shared/types/PageStatuses';
import { useCurrentProject } from '../../../lib/hooks/useCurrentProject';
export function useDocumentProceed() {
    const { project } = useCurrentProject();
    const { tproject } = useTranslationProject();
    const { referrerUrl } = useReferrer();
    const { documentId, pageId, document, page, pageCount, activePageIndex, reset } = useDocumentStore();
    const navigate = useNavigate();
    const [isProceeding, setIsProceeding] = useState(false);
    const handleProceed = () => __awaiter(this, void 0, void 0, function* () {
        if (documentId && document && pageId && page) {
            const preparedPagePredictions = preparePageDataOnSave(page.predictions);
            setIsProceeding(true);
            if (activePageIndex < pageCount - 1 && document.status !== DocumentStatuses.validated) {
                try {
                    const nextPageId = (activePageIndex + 1).toString();
                    const nextDocument = yield DocumentService.getPage(project.id, documentId, nextPageId);
                    const nextStatus = nextDocument.status;
                    if (nextStatus === PageStatuses.ready || nextStatus === PageStatuses.validated) {
                        DocumentService.updatePage(project.id, documentId, page.page_id, false, preparedPagePredictions)
                            .then(() => {
                            reset();
                            navigate(ROUTES.DOCUMENT_REVIEW.replace(':projectId', project.id)
                                .replace(':docId', documentId)
                                .replace(':pageId', nextPageId));
                        })
                            .catch(err => console.log(err))
                            .finally(() => {
                            setIsProceeding(false);
                            reset();
                        });
                    }
                    else {
                        setIsProceeding(false);
                        ToastService.error(tproject('The next page is not ready yet'));
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                DocumentService.updatePage(project.id, documentId, page.page_id, true, preparedPagePredictions)
                    .then(() => {
                    reset();
                    if (referrerUrl) {
                        navigate(referrerUrl);
                    }
                    else {
                        navigate(ROUTES.DOCUMENT_SUMMARY.replace(':projectId', project.id).replace(':id', documentId));
                    }
                })
                    .catch(err => console.log(err))
                    .finally(() => {
                    setIsProceeding(false);
                    reset();
                });
            }
        }
    });
    return {
        handleProceed,
        isProceeding
    };
}
